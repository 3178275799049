import React from "react";
import "./currentSong.css";

class CurrentSong extends React.Component {
  render() {
    return (
      <div className="current">
        <div className="currentBubble"></div>
        <div className="blub"></div>
        <div className="currentTitleLabel">
          <div className="currentSong">
            {this.props.artist}
            {this.props.track}
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentSong;
