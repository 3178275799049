import React from "react";
import YouTube from "react-youtube";
import "./myYoutubePlayer.css";

function _onReady(event) {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
}

function _onPlay(event) {
  // access to player in all event handlers via event.target
  const player = event.target;
  console.log(player.getCurrentTime());
}

var opts = {
  height: '400',
  width: '1024',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};

class MyYoutubePlayer extends React.Component {

  render() {
    return (
      <div className="youtube">
        <YouTube videoId={this.props.youtubeSearchQuery} opts={opts} onReady={_onReady} onPlay={_onPlay} />
      </div>
    );
  }
}

export default MyYoutubePlayer;
