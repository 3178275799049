import React, { useState } from "react";
import "./App.css";
import CurrentSong from "./CurrentSong";
import MyYoutubePlayer from "./MyYoutubePlayer";
import * as youtubeSearch from "youtube-search";

import firestore, {
  useSongs,
  // deleteSong,
  giveId
} from "./firestore.js";

var opts = {
  maxResults: 1,
  key: 'AIzaSyCT5YNj0WpEUrt_4K8b3GZ6NoBZTOImXMA'
};


function checkIfImportant(isItImportant) {
  if (isItImportant) {
    return <button className="isImportantButton"></button>;
  }
  return <div />;
}



function App() {
  const songs = useSongs();
  const [artistName, setArtistName] = React.useState("No Song selected - ");
  const [trackName, setTrackName] = React.useState("Not defined yet");
  const [youtubeQuery, setYoutubeQuery] = React.useState("Qll7IHN0I4Q");

  return (
    <div className="App">
      <MyYoutubePlayer className="myYoutubePlayer" youtubeSearchQuery={youtubeQuery}>
      </MyYoutubePlayer>
      <div className="lineContaineContainer">
        <CurrentSong artist={artistName} track={trackName}></CurrentSong>
        {songs.map(song => (
          <div
            className="lineContainer"
            onClick={() => {
              setArtistName(song.seperatedString);
              setTrackName(""); //TODO DB update + seperated String splitting
              youtubeSearch(song.seperatedString, opts, (err, results) => {
                if (err) return console.log(err);
                setYoutubeQuery(results[0].id);
              });
            }}
          >
            <a type="button" className="youtubeButton"></a>
            <div className="seperatedString">{song.seperatedString}</div>
            {/*           {<button className="deleteButton" onClick={deleteSong(song.id)}>
            delete
          </button>} */}
            {checkIfImportant(song.isImportant)}
            <button className="moreOptions"></button>
          </div>
        ))}
      </div>

      <div className="blubgray"></div>
    </div>
  );
}

export default App;
